import React from 'react'

import {
    CombinationContentArea,
    FinderTitle,
    InfoBar,
    VehicleTypeOptions,
    VehicleTypeListItem,
    VehicleTypeItem,
    CombinationLetterCounterContainer,
    CombinationLetterCounter,
    CombinationInputContainer,
    CombinationInput,
    CombinationActionContainer,
    CombinationSearchResult,
    ResetCombination,
    PlateHole,
    CombinationInputRow,
    CombinationFinderHiddenElement,
    CombinationInputContainerRowTop,
    CombinationInputContainerRowBottom,
    RedesignMessage,
    SearchResultButtonWrapperColumn,
    VehicleTypeLeftColumn,
    VehicleTypeRightColumn,
    VehicleTypeGrid
} from './CombinationFinder.styles'
import { List, Grid, Responsive } from 'semantic-ui-react'
import { KpButton } from '@elements/index'
import theme from '@styles/theme'
import { navigate } from 'gatsby'
import { CombinationPricingOptions } from '@components/CombinationPricingOptions'
import { PlateFormat } from '@models/index'
import { PlateSuggestions } from '@components/PlateSuggestions'
import { CheckCombinationService } from '@services/CheckCombinationService'
import { VehicleType, CurrentPlateDesign, CombinationSettings, VehicleTypesSetting, TransactionType, TransactionTypesSetting } from '@models/ProductModels'
import QuickHelpInfoLink from './QuickHelpInfo'
import { GA4Event, OrderTypes, ReCaptchaThreshold } from '@utils/Constant'
import { GAService, SubscriptionService } from '@services/index'
import { date } from '@storybook/addon-knobs'
import ReCaptcha from '@utils/reCaptcha'
import { ReCaptchaService } from '@services/ReCaptchaService'
import { Campaign } from '@models/Campaign/Campaign'
import SweepstakeCampaign from '../Campaign/SweepstakeCampaign'
import SweepstakeAds from '@components/Campaign/SweepstakeAds'
import { LOCALSTORAGE_CAMPAIGN_CURRENT } from '@redux/localStorage'
import { CampaignModel } from '@components/Campaign/CampaignModel'
import { getCurrentCheckout } from '@redux/localStorage/checkout'
import VehicleTypeSelector from './VehicleTypeSelector'


interface FinderProps {
    title: string,
    plateformats: PlateFormat[]
    vehicleType: VehicleType
    transactionType: TransactionType
    combination: string
    isGift?: boolean
    plateDesign?: CurrentPlateDesign
    categoryName?: string
    apiUrl: string
    reCaptchaSiteKey: string
    reCaptchaSiteKeyV2: string
    campaign: Campaign,
    emailValidationToken: string
    alternativeMessage: string,
    designId: number
    darkTheme: boolean
}
interface FinderStates {
    vehicleType: VehicleType
    isCheckingCombination: boolean
    combination: string
    combinationChecked: boolean
    combinationAvailable: boolean
    oldCombination: string
    characterCombinationCount: number
    spacingCombinationCount: number,
    vehicleTheme: any,
    isForRedesign: boolean,
    isForUpgrade: boolean,
    reCaptchaToken?: string,
    reCaptchaError: string,
    isCampaignDialogOpen: boolean,
    isReturnUser: boolean,
    alreadyShow: boolean
}



class CombinationFinder extends React.Component<FinderProps, FinderStates>{
    plateFormatPricingRef: React.RefObject<CombinationPricingOptions>;
    plateSuggestionsRef: React.RefObject<PlateSuggestions>;

    constructor(props: FinderProps) {
        super(props);
        this.state = {
            vehicleType: props.vehicleType,
            combination: props.combination,
            combinationChecked: false,
            combinationAvailable: false,
            characterCombinationCount: 0,
            oldCombination: '',
            spacingCombinationCount: 0,
            isCheckingCombination: false,
            vehicleTheme: theme.vehicleTheme.dark,
            isForRedesign: false,
            isForUpgrade: false,
            reCaptchaToken: undefined,
            reCaptchaError: "",
            isCampaignDialogOpen: false,
            isReturnUser: false,
            alreadyShow: false
        }
        this.plateFormatPricingRef = React.createRef();
        this.plateSuggestionsRef = React.createRef();
    }

    componentDidMount() {
        if (this.state.combination !== '') {
            this.handleInputChange({ target: { value: this.state.combination } });
            this.handleCombinationCheck();
        }
    }

    getCombinationWidth = (combination: string) => {

        const largeCharLength = combination.replace(CombinationSettings.largeCharPattern, "").length;
        const smallCharLength = combination.replace(CombinationSettings.smallCharPattern, "").length;
        const spaceLength = combination.replace(CombinationSettings.spacePattern, "").length;
        const totalLength = (largeCharLength * this.state.vehicleType.largeCharWidth) + (smallCharLength * this.state.vehicleType.smallCharWidth) + (spaceLength * this.state.vehicleType.spaceCharWidth);

        return {
            largeCharacterLength: largeCharLength,
            smallCharacterLength: smallCharLength,
            spaceLength: spaceLength,
            totalWidth: totalLength
        }
    }

    handleInputChange = (event: any) => {
        this.setState({ combination: event.target.value, combinationChecked: false });
        if (this.plateFormatPricingRef.current != null) this.plateFormatPricingRef.current.clearSelectCombinationFormat();

        let combination = event.target.value;
        const combinationWidth = this.getCombinationWidth(combination);

        if (combinationWidth.totalWidth <= this.state.vehicleType.maxWidth)
            this.setState({
                characterCombinationCount: combinationWidth.largeCharacterLength + combinationWidth.smallCharacterLength,
                spacingCombinationCount: combinationWidth.spaceLength
            })

        if ((combinationWidth.largeCharacterLength + combinationWidth.smallCharacterLength) > this.state.vehicleType.maxChars || combinationWidth.totalWidth > this.state.vehicleType.maxWidth) {
            this.setState({
                combination: this.state.oldCombination,
                characterCombinationCount: this.getCombinationWidth(this.state.oldCombination).largeCharacterLength + this.getCombinationWidth(this.state.oldCombination).smallCharacterLength,
            });

        } else {
            var transformedInput = combination.replace(CombinationSettings.allowed, "");
            if (transformedInput !== combination) {
                this.setState({ combination: transformedInput });
            }
            this.setState({
                oldCombination: transformedInput
            });
        }
    }

    handleKeyPress = (event: any) =>{
        if (event.key === "Enter") {
            this.handleCombinationCheck();
        }
    };
    resetCombination = () => {
        this.setState({
            combination: '',
            combinationChecked: false,
            combinationAvailable: false,
            characterCombinationCount: 0
        });
        if (this.plateFormatPricingRef.current != null) this.plateFormatPricingRef.current.clearSelectCombinationFormat();
    }
    handleCombinationCheck = (isSuggestion = false) => {
        this.setState({
            isCheckingCombination: true
        })
        const combination = this.state.combination;        
        this.checkCombinationAvailable(combination).then((combinationCheckResult) => {
            var isAvailable = combinationCheckResult.Available
            if (isAvailable) {
                this.setState(
                    {
                        combinationChecked: true,
                        combinationAvailable: true,
                        isCheckingCombination: false,
                        isForUpgrade: combinationCheckResult.IsAvailableForUpgrade ? true : false,
                        isForRedesign: combinationCheckResult.IsAvailableForRedesign ? true : false
                        
                    });
                if (this.plateFormatPricingRef.current != null) this.plateFormatPricingRef.current.selectCombinationFormat(combination);

                const campaignInLocal: string | null = localStorage.getItem(LOCALSTORAGE_CAMPAIGN_CURRENT);
                let campaignItem : CampaignModel
                if(campaignInLocal !== null){
                    campaignItem = JSON.parse(campaignInLocal) as CampaignModel;
                    if(campaignItem.campaignName == this.props.campaign.name && campaignItem.email){
                        this.setState({isReturnUser: true})
                    } else if(campaignItem.campaignName == this.props.campaign.name && !campaignItem.email) {
                        this.setState({alreadyShow: true})
                    }
                } else {
                    localStorage.setItem(LOCALSTORAGE_CAMPAIGN_CURRENT, JSON.stringify({campaignName: this.props.campaign.name, email: "", time:Date.now()})); 
                }     
                setTimeout(() => {
                    this.setState({isCampaignDialogOpen: true})
                }, 1500)
            } else {
                this.setState(
                    {
                        combinationChecked: true,
                        combinationAvailable: false,
                        isCheckingCombination: false,
                        isForUpgrade: combinationCheckResult.IsAvailableForUpgrade ? true : false,
                        isForRedesign: combinationCheckResult.IsAvailableForRedesign ? true : false,
                        isCampaignDialogOpen: false
                    });
                if (this.plateSuggestionsRef.current != null) this.plateSuggestionsRef.current.loadSuggestions(combination);
            }
            
            const saved = localStorage.getItem("checkCombination");
            if(saved) {
                let initialValue = JSON.parse(saved);
                var t = new Date();

                if (initialValue.combination == this.state.combination && initialValue.time > t.setSeconds(t.getSeconds() - 3)){                
                    return
                }
            }            
            localStorage.setItem("checkCombination", JSON.stringify({combination: this.state.combination, time:Date.now()}));

            GAService.trackEvent('chooseCombination', {
                combination: combination,
                orderType: OrderTypes.New,
                isAvailable: isAvailable,
                restyleVSnewdesign: "New Design",
            });
            GAService.trackEventGA4({
                event: GA4Event.Search,
                searchedCombination: combination,
                availableForRedesign: null,
                availableForUpgrade: null,
                isAvailable: isAvailable,
                orderType: OrderTypes.New,
                selectedSuggestion: isSuggestion ? combination : '', 
                fromSuggestion: isSuggestion
            });
        });
    }

    checkCombinationAvailable = (combination: string) => {
        return CheckCombinationService.checkCombination(this.props.apiUrl, combination, this.state.vehicleType.id).then((combinationCheckResult) => {
            return combinationCheckResult;
        });
    }

    setCombinationSuggestion = (combination: string) => {
        this.setState({
            combination: combination
        }, () => {
            this.handleCombinationCheck(true);

            GAService.trackEvent("plateSuggestions", {
                combination: combination,
                isAvailable: true,
                orderType: OrderTypes.New,
                restyleVSnewdesign: 'New Design',
            })
    
            GAService.trackEventGA4({
                event: GA4Event.PlateSuggestions,
                selectedSuggestion: combination,
                isAvailable: true,
                orderType: OrderTypes.New,
                fromSuggestion: true,                
                availableForRedesign: null,
                availableForUpgrade: null
            });
        })
    }
    chooseDesign = (designId: number, iOwnThisPlate: boolean = false) => {
        let transactionType = this.props.vehicleType.id !== this.state.vehicleType.id ? this.props.transactionType : this.props.transactionType
        if (iOwnThisPlate) {
            if (this.state.isForRedesign) {
                transactionType = TransactionTypesSetting.Redesign
            }
            if (this.state.isForUpgrade) {
                transactionType = TransactionTypesSetting.Upgrade
            }
            GAService.trackEvent('chooseCombination', {
                combination: this.state.combination,
                orderType: "Redesign",
                restyleVSnewdesign: "Redesign",
                isAvailable: this.state.isForRedesign || this.state.isForUpgrade
            });

            GAService.trackEventGA4({
                event: GA4Event.Search,
                searchedCombination: this.state.combination,
                availableForRedesign: this.state.isForRedesign,
                availableForUpgrade: this.state.isForUpgrade,
                isAvailable: this.state.isForRedesign || this.state.isForUpgrade,
                orderType: OrderTypes.Redesign,
                selectedSuggestion: '', 
                fromSuggestion: false
            });
        }

        if (this.props.vehicleType.id !== this.state.vehicleType.id) {
            navigate("/select-a-style/?combination=" + this.state.combination, {
                state: {
                    combination: this.state.combination,
                    plateDesign: undefined,
                    vehicleType: this.state.vehicleType,
                    transactionType: transactionType,
                    categoryName: undefined,
                    isGift: this.props.isGift,
                    productid: null
                }
            });
        } else {
            navigate("/select-a-style/?combination=" + this.state.combination + (designId ? "&productid="+designId : ''), {
                state: {
                    combination: this.state.combination,
                    plateDesign: this.props.plateDesign,
                    vehicleType: this.state.vehicleType,
                    transactionType: transactionType,
                    categoryName: this.props.categoryName,
                    isGift: this.props.isGift,
                    productid: this.props.designId
                }
            });
        }
    }    

    changeVehicleType = (vehicleType: VehicleType) => {
        let vehicleTheme = null
        switch (vehicleType.name) {
            case VehicleTypesSetting.Car.name:
                vehicleTheme = theme.vehicleTheme.dark
                break;
            default:
                vehicleTheme = theme.vehicleTheme.dark
                break;
        }

        this.setState({
            vehicleType: vehicleType,
            combination: '',
            characterCombinationCount: 0,
            combinationChecked: false,
            combinationAvailable: false,
            vehicleTheme: vehicleTheme
        });
        if (this.plateFormatPricingRef.current != null) this.plateFormatPricingRef.current.clearSelectCombinationFormat();
    }

    closeModal = () => {
        this.setState({ isCampaignDialogOpen: false })
    } 
    
    clickSweepstakeAds =() => {
        if(this.state.combinationAvailable){
            this.setState({ isCampaignDialogOpen: true })
            if(this.state.isReturnUser) {
                this.setState({ isReturnUser: false })
            }       
            if(this.state.alreadyShow){
                this.setState({ alreadyShow: false })
            }      
        } else{
            this.setState({ isCampaignDialogOpen: false })
        }       
    }

    render() {
        const { title, campaign, darkTheme } = this.props;
        const { vehicleType, combination, combinationChecked, combinationAvailable, characterCombinationCount, vehicleTheme, isForRedesign, isForUpgrade } = this.state;
        const isCampaignActive = campaign && ( new Date(campaign.validFrom) < new Date() && new Date() < new Date(campaign.expiresOn))
           
        const isNewCampaign = campaign && (campaign.transactionTypes.filter(c => c.name == 'New').length > 0)
        const checkout = getCurrentCheckout()
        const isDealerOrder = checkout && checkout.isDealerOrder ? true : false;
        
        return (

            <Grid>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={10} widescreen={10} floated='left'>                
                    <CombinationContentArea>                    
                        {
                            title && <FinderTitle textAlign='center'>{title}</FinderTitle>
                        }
                        <InfoBar>
                            <VehicleTypeOptions>
                                <List horizontal>
                                    <VehicleTypeListItem>
                                        <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Car.name} darkTheme={darkTheme} selected={vehicleType.id === VehicleTypesSetting.Car.id} onClick={() => this.changeVehicleType(VehicleTypesSetting.Car)}>
                                            <VehicleTypeSelector selected={vehicleType.id === VehicleTypesSetting.Car.id} darkTheme={darkTheme} vehicleType={VehicleTypesSetting.Car} characterCombinationCount={characterCombinationCount}/>                                        
                                        </VehicleTypeItem>
                                    </VehicleTypeListItem>
                                    <VehicleTypeListItem>
                                        <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Trailer.name} darkTheme={darkTheme} selected={vehicleType.name === VehicleTypesSetting.Trailer.name} onClick={() => this.changeVehicleType(VehicleTypesSetting.Trailer)}>
                                            <VehicleTypeSelector selected={vehicleType.id === VehicleTypesSetting.Trailer.id} darkTheme={darkTheme} vehicleType={VehicleTypesSetting.Trailer} characterCombinationCount={characterCombinationCount}/>
                                        </VehicleTypeItem>
                                    </VehicleTypeListItem>
                                    <VehicleTypeListItem>
                                        <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Motorbike.name} darkTheme={darkTheme} selected={vehicleType.name === VehicleTypesSetting.Motorbike.name} onClick={() => this.changeVehicleType(VehicleTypesSetting.Motorbike)}>
                                            <VehicleTypeSelector selected={vehicleType.id === VehicleTypesSetting.Motorbike.id} darkTheme={darkTheme} vehicleType={VehicleTypesSetting.Motorbike} characterCombinationCount={characterCombinationCount}/>
                                        </VehicleTypeItem>
                                    </VehicleTypeListItem>
                                </List>
                                <QuickHelpInfoLink darkTheme={darkTheme}/>
                            </VehicleTypeOptions>
                            
                        </InfoBar>
                        <CombinationInputContainer vehicleTheme={vehicleTheme} darkTheme={darkTheme}>
                            <Grid>
                                <CombinationInputContainerRowTop>
                                    <Grid.Column width={4} floated='left'>
                                        <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                        <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                                    </Grid.Column>
                                    <Grid.Column width={4} floated='right'>
                                        <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                        <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                                    </Grid.Column>
                                </CombinationInputContainerRowTop>
                                <CombinationInputRow centered>
                                    <Grid.Column textAlign='center' width={16}>
                                        <CombinationInput aria-label='Search Your Combination' spellCheck='false' autocomplete="off" autocorrect="off" placeholder={this.state.vehicleType.id === 1 ? 'ABC123' : 'ABC12'} onChange={(event: any) => this.handleInputChange(event)} value={combination} vehicleTheme={vehicleTheme} darkTheme={darkTheme} onKeyPress={(event: any) => this.handleKeyPress(event)}/>
                                    </Grid.Column>
                                </CombinationInputRow>
                                <CombinationInputContainerRowBottom>
                                    <Grid.Column width={4} floated='left'>
                                        <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                        <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                                    </Grid.Column>
                                    <Grid.Column width={4} floated='right'>
                                        <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                        <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                                    </Grid.Column>
                                </CombinationInputContainerRowBottom>
                                
                            </Grid>
                        </CombinationInputContainer>                        
                        {
                            combinationChecked && combinationAvailable &&
                            <CombinationSearchResult available={true}>
                                Your Combination is Available!
                                        </CombinationSearchResult>
                        }
                        {
                            combinationChecked && !combinationAvailable &&
                            <CombinationSearchResult available={false}>
                                Your Combination is not Available!
                                        </CombinationSearchResult>
                        }                        
                        <CombinationActionContainer>
                            {
                                !combinationChecked &&                                
                                    <span id="cfca" ><KpButton fullWidth="mobile" id="cfca" loading={this.state.isCheckingCombination} disabled={characterCombinationCount <= 0} color={theme.brand.colors.blue} buttonType='primary' onClick={() => this.handleCombinationCheck()} darkTheme={true}>
                                        Check Availability
                                    </KpButton></span>
                            }
                            {
                                combinationChecked && combinationAvailable &&
                                <div>
                                    <span id='cfc'><KpButton fullWidth="mobile" id='cfc' color={theme.brand.colors.blue} buttonType='primary' onClick={()=>this.chooseDesign(this.props.designId, false)} darkTheme={true}>
                                        Continue
                                    </KpButton></span><br/><br/>
                                    <ResetCombination id='cfrc' onClick={this.resetCombination}>
                                        Reset Combination
                                    </ResetCombination>
                                    
                                    {isCampaignActive && !this.state.isReturnUser && this.state.isCampaignDialogOpen && isNewCampaign && !isDealerOrder && !this.state.alreadyShow &&
                                    <SweepstakeCampaign 
                                        campaign={this.props.campaign} 
                                        reCaptchaSiteKey={this.props.reCaptchaSiteKey} 
                                        reCaptchaSiteKeyV2={this.props.reCaptchaSiteKeyV2}
                                        apiUrl={this.props.apiUrl} 
                                        emailValidationToken={this.props.emailValidationToken} 
                                        modalOpen={true} 
                                        onClose={this.closeModal}                                        
                                        />}
                                </div>
                            }
                            {
                                <div>
                                    {combinationChecked && !combinationAvailable && 
                                        <div>
                                            <Responsive minWidth={theme.responsive.tablet.minWidth}>
                                                <RedesignMessage>
                                                    {this.props.alternativeMessage}
                                                </RedesignMessage>
                                            </Responsive>
                                            
                                            <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                                                <RedesignMessage>
                                                    {this.props.alternativeMessage}
                                                </RedesignMessage>
                                            </Responsive>
                                            <Grid>
                                                <Grid.Row centered>
                                                {(isForRedesign || isForUpgrade) ?
                                                    <>
                                                        <SearchResultButtonWrapperColumn maxWidth={45} marginRight={12} mobile={8} tablet={5} computer={5} largeScreen={5} widescreen={5} textAlign='center'>
                                                            <span id='cfsa'><KpButton fullWidth="mobile" id='cfsa' textColor={theme.brand.colors.white} color={theme.brand.secondary} buttonType='darkModeSecondary' onClick={this.resetCombination}>
                                                                Start Again
                                                            </KpButton></span>
                                                        </SearchResultButtonWrapperColumn>                                                        
                                                        <SearchResultButtonWrapperColumn maxWidth={45} mobile={8} tablet={6} computer={6} largeScreen={6} widescreen={6} textAlign='center'>                                                   
                                                            <span id='rcfbnp'><KpButton fullWidth='mobile' color={theme.brand.colors.green} id='rcfbnp' buttonType='primary' onClick={() => this.chooseDesign(this.props.designId, true)} darkTheme={true}>
                                                                I Own This Plate
                                                            </KpButton></span>
                                                        </SearchResultButtonWrapperColumn>
                                                    </> : <>
                                                        <SearchResultButtonWrapperColumn maxWidth={93} mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16} textAlign='center'>
                                                            <span id='cfsa'><KpButton fullWidth="mobile" id='cfsa' textColor={theme.brand.colors.white} color={theme.brand.secondary} buttonType='darkModeSecondary' onClick={this.resetCombination}>
                                                                    Start Again
                                                            </KpButton></span>
                                                            </SearchResultButtonWrapperColumn> 
                                                        </>
                                                    }
                                                </Grid.Row>
                                            </Grid>
                                        </div>
                                    }

                                </div>
                            }
                            {
                                isCampaignActive && isNewCampaign && this.props.campaign.ads && !isDealerOrder && (!combinationChecked || !combinationAvailable) &&                                  
                                <div onClick={this.clickSweepstakeAds}><SweepstakeAds campaign={campaign} available={combinationChecked && combinationAvailable}/></div>
                            }
                        </CombinationActionContainer>
                        
                    </CombinationContentArea>                
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={6} widescreen={6} floated='right'>
                    <CombinationFinderHiddenElement className={combinationChecked && !combinationAvailable ? 'hidden' : ''}>
                        <CombinationPricingOptions ref={this.plateFormatPricingRef} combinationFormats={this.props.plateformats}></CombinationPricingOptions>
                    </CombinationFinderHiddenElement>
                    <CombinationFinderHiddenElement className={!combinationChecked || combinationAvailable ? 'hidden' : ''}>
                        <PlateSuggestions apiUrl={this.props.apiUrl} vehicleType={this.state.vehicleType} ref={this.plateSuggestionsRef} selectSuggestion={this.setCombinationSuggestion} suggestionsToShow={12}></PlateSuggestions>
                    </CombinationFinderHiddenElement>
                </Grid.Column>

            </Grid>

        );
    }
}

export default CombinationFinder;