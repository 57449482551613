import * as React from 'react'

declare global {
    interface Window {
      captchaOnLoad: () => void
      grecaptcha: ReCaptchaInstance
    }
  }
  
  interface ReCaptchaInstance {
    ready: (cb: () => any) => any
    execute: (options: ReCaptchaExecuteOptions) => Promise<string>
    render: (id: string, options: ReCaptchaRenderOptions) => any
    getResponse: (id: number | undefined) => any    
    reset: (id: number | undefined) => any
  }
  
  interface ReCaptchaExecuteOptions {
    action: string
  }
  
  interface ReCaptchaRenderOptions {
    sitekey: string
    size: string
  }
  
  interface Props {
    action: string,
    reCaptchaSiteKey: string,
    reCaptchaSiteKeyV2: string,
    children: (props: CaptchaProps) => React.ReactNode
  }
  
  interface CaptchaProps {
    isReady: boolean
    execute: () => Promise<string>
    widgetV2: number | undefined
  }
  
  interface State {
    readonly isReady: boolean
  }
  
  class ReCaptcha extends React.PureComponent<Props, State> {
    state: State = {
      isReady: false
    }
  
    private script: HTMLScriptElement | undefined
    private widget: HTMLDivElement | undefined
    private widget_v2: number | undefined
  
    componentDidMount(): void {
      this.loadScript()
    }
  
    componentWillUnmount(): void {
      if(this.script && typeof document !== 'undefined') document.body.removeChild(this.script)   
      if(this.widget && typeof document !== 'undefined') document.body.removeChild(this.widget)   
      this.widget_v2 = undefined
    }
  
    render(): React.ReactNode {
      if(typeof document !== 'undefined'){
        const widget = document.createElement('div')
        widget.id = 'g-recaptcha' + "_" + this.props.action
        widget.className = 'g-recaptcha'
        if(!this.widget){
            this.widget = document.body.appendChild(widget)
        }
      }        
      return this.props.children({
        isReady: this.state.isReady,
        execute: this.executeCaptcha,
        widgetV2: this.widget_v2
      })        
    }
  
    private loadScript = (): void => {      
      if (typeof document !== 'undefined' && typeof window !== 'undefined'){
        window.captchaOnLoad = this.onLoad
  
        const url = 'https://www.google.com/recaptcha/api.js'
        const queryString = '?onload=captchaOnLoad&render=explicit'
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url + queryString
        script.async = true
        script.defer = true
        script.id = 'google-recaptcha-v3'
    
        if (!document.getElementById(script.id)){
          this.script = document.body.appendChild(script)
        }   
      }         
    }
  
    private onLoad = (): void => {     
      
      if (typeof document !== 'undefined' && typeof window !== 'undefined'){
        var elements = document.getElementsByClassName('g-recaptcha')
        if(elements[0]){
          if(elements[0].children.length == 0){
            window.grecaptcha.render(elements[0].id, {
              sitekey: this.props.reCaptchaSiteKey,
              size: 'invisible'
            })
          }
          
          elements[0].children[0].style.bottom = "90px"                
        }

        var elements_v2 = document.getElementsByClassName('g-recaptcha-v2')
        if(elements_v2[0]){
          if(elements_v2[0].children.length == 0){
            this.widget_v2 = window.grecaptcha.render(elements_v2[0].id, {
                sitekey: this.props.reCaptchaSiteKeyV2,
                size: ''
            })
          }
        }

        window.grecaptcha.ready(() => {
          this.setState({ isReady: true })
        })
      } 
    }

    private executeCaptcha = (): Promise<string> => {
      if (typeof window !== 'undefined'){
        return window.grecaptcha.execute({ action: this.props.action })
      }
      return new Promise<string>('')
    }
  }

  export default ReCaptcha
