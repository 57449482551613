import styled from "@emotion/styled";
import mq from "@styles/mq";
import React from "react";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";

export const OutsideColumnWrapper = styled(GridColumn)`
    margin-top:30px;
    color:white;  
    margin-left: auto;
    margin-right: auto;
    padding:15px;
`

export const LeftColumnWrapper = styled(({bgColor, ...rest})=> <GridColumn {...rest} />)`    
    ${mq({
        borderBottomLeftRadius:["0px", "0px", "10px"],
        borderTopRightRadius:["10px", "10px", "0px"]
    })}
    padding-top: 20px;
    border-top-left-radius: 10px;
    background-color: ${props => props.bgColor};
    
`
export const RightColumnWrapper = styled(({bgColor, ...rest})=> <GridColumn {...rest} />)`
    ${mq({        
        borderBottomLeftRadius:["10px", "10px", "0px"],
        borderTopRightRadius:["0px", "0px", "10px"],
        paddingLeft: ["30px !important", "30px !important", "30px !important"],
        paddingRight: ["30px !important", "30px !important", "30px !important"]
    })}
    padding-top: 20px;
    border-bottom-right-radius: 10px;
    background-color: ${props => props.bgColor};  
`

export const CampaignAdsLeftDescription = styled.div`
    font-size: 14px;
    font-weight: 100;    
    padding-left:10px;
    padding-right:10px;
`
export const CampaignAdsRightTitle = styled.div`
    font-weight: 100;    
    text-align:left;
    ${mq({
        fontSize: ["16px", "16px", "18px"]        
    })}
    border-bottom: 1px white solid;
    line-height: 65px;
    
`
export const CampaignAdsRightDescription = styled.div`
    ${mq({
        fontSize: ["16px", "16px", "18px"],
        paddingTop:["15px","20px","20px"],
        paddingBottom:["10px","0px","0px"]        
    })}
    text-align: left;
    font-weight: 100; 
    border-bottom: 1px white solid;    
`
export const LeftTitleLine1 = styled.div`
    font-size: 30px;
    font-weight: bold;
`
export const LeftTitleLine2 = styled.div`
    font-size: 20px;
    font-weight: bold;
`
export const FooterRowWrapper = styled(GridRow)`
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    ${mq({
        height: ["20px", "20px", "20px"]     
    })}
`
export const ButtonWrapper = styled.div`
    margin-top:10px;
`